<template>
  <v-container grid-list-xl>
    <v-layout row wrap>
      <v-flex lg12 sm12 xs12>
        <Search />
        <v-data-table
          class="elevation-1"
          :headers="table.header"
          :items="table.content"
          :item-key="'id'"
          :loading="table.isLoading"
          :items-per-page="table.paginate.inputPage.size"
          :options.sync="pagination"
          :server-items-length="table.total"
          :footer-props="{
            'items-per-page-options': [10, 20, 30, 40, 50, 100],
            showFirstLastPage: true,
            showCurrentPage: true,
          }"
          @click:row="goToDetail"
        >
          <template v-slot:item.status="{ item }">
            {{ item.status | statusPedido }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | date }}
          </template>
          <template v-slot:item.group="{ item }">
            <span class="text-uppercase">{{ item.group }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <router-link
              :to="{ name: 'pedidos-detail', params: { id: item.id } }"
              title="Visualizar Pedido"
            >
              <v-icon>visibility</v-icon>
            </router-link>
          </template>
        </v-data-table>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Search from "./Search";

export default {
  components: {
    Search,
  },
  data: () => ({
    pagination: null,
  }),
  methods: {
    changePagination() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      const paginate = {
        ...this.table.paginate,
        input: { ...this.table.paginate.input },
        inputPage: {
          page: page,
          size: itemsPerPage,
          order: sortBy.length > 0 ? sortBy[0] : "id",
          descingOrder: sortDesc.length > 0 ? sortDesc[0] : true,
        },
      };

      this.changePage(paginate);
      this.search();
    },
    goToDetail({ id }) {
      this.$router.push({ name: "pedidos-detail", params: { id } });
    },
    ...mapActions("pedido", { search: "SEARCH" }),
    ...mapActions("pedido", { clear: "CLEAR" }),
    ...mapActions("pedido", { changePage: "CHANGE_PAGE" }),
  },
  computed: {
    ...mapState("pedido", ["table"]),
  },
  watch: {
    pagination: {
      handler() {
        this.changePagination();
      },
      deep: true,
    },
  },
  destroyed() {
    this.clear();
  },
};
</script>
