<template>
  <v-form ref="form" lazy-validation @submit.prevent="onSubmit" class="app-form">
    <v-expansion-panels class="app-expansion-panels" v-model="panel">
      <v-expansion-panel @click.stop="changeCollapse">
        <v-expansion-panel-header class="app-expansion-panel-header mb-0">
          <h4 class="text">
            <v-icon>filter_alt</v-icon>
            <span>Filtrar Pedidos</span>
          </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="app-expansion-panel-content">
          <v-row class="mt-3">
            <v-col :lg="4" :md="4" :sm="4" :xs="12" class="pt-0">
              <v-select
                v-model="table.paginate.input.type"
                :items="tipoPedido"
                item-text="name"
                item-value="id"
                :clearable="true"
                label="Escolha o tipo de pedido"
                single-line
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col :lg="4" :md="4" :sm="4" :xs="12" class="pt-0">
              <v-select
                v-model="table.paginate.input.status"
                :items="statusPedido"
                :item-text="v => v[1]"
                :item-value="v => v[0]"
                :clearable="true"
                label="Escolha o status do pedido"
                single-line
                outlined
                dense
              ></v-select>
            </v-col>
            <v-col :lg="4" :md="4" :sm="4" :xs="12" class="pt-0">
              <v-text-field
                v-model="table.paginate.input.group"
                :clearable="true"
                label="Grupo do pedido"
                single-line
                outlined
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <div class="mt-3 d-flex">
            <v-spacer></v-spacer>
            <v-btn
              outlined
              color="error"
              type="button"
              @click.prevent.stop="clearFilter"
              class="mr-2"
            >
              <v-icon size="14">delete</v-icon>Limpar Filtros
            </v-btn>
            <v-btn depressed color="success" type="submit">Filtrar</v-btn>
          </div>

          <section class="loading" v-if="table.isLoading">
            <v-progress-circular indeterminate color="success"></v-progress-circular>
          </section>
          <div id="endForm"></div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { StatusPedidoEnum } from "@/models/status-pedido.enum";

import TipoPedidoService from "@/services/tipo-pedidos.service";

export default {
  data: () => ({
    panel: null,
    tipoPedido: null,
    statusPedido: Object.entries(StatusPedidoEnum),
    dateCriacaoMenu: false,
    dateCriacao: null
  }),
  methods: {
    async onSubmit() {
      await this.search();
      this.$vuetify.goTo("#endForm");
    },
    clearFilter() {
      this.clear();
      this.search();
    },
    async loadTiposPedido() {
      const paginate = {
        input: {
          name: ""
        },
        inputPage: {
          page: 1,
          size: 50,
          order: "id",
          descendingOrder: true
        }
      };
      const { items } = await TipoPedidoService.page(paginate).toPromise();
      this.tipoPedido = items;
    },
    changeCollapse() {},
    ...mapActions("pedido", { search: "SEARCH" }),
    ...mapActions("pedido", { clear: "CLEAR" }),
    ...mapActions("pedido", { changePage: "CHANGE_PAGE" })
  },
  computed: {
    ...mapState("pedido", ["table"])
  },
  watch: {
    dateCriacao(value) {
      this.table.paginate.input.dataCriacao = value;
    }
  },
  mounted() {
    this.loadTiposPedido();
  }
};
</script>
